import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { action, createStore, StoreProvider, thunk } from 'easy-peasy';

export const model = {
  User: {
    info: {},
    email: '',
    password: '',
    jwt: '',
    loggedIn: false,
    userStatePendinng: true,
    setUserEmail: action((state, payload) => {
      state.email = payload;
    }),
    setUserPassword: action((state, payload) => {
      state.password = payload;
    }),
    setJWT: action((state, payload) => {
      state.jwt = payload;
    }),
    setLoggedIn: action((state, payload) => {
      state.loggedIn = payload;
    }),
    setUserStatePending: action((state, payload) => {
      state.userStatePendinng = payload;
    }),
    setUserInfo: action((state, payload) => {
      state.info = payload;
    }),
    signupUsername: thunk(async (actions, payload) => {
      const results = await signupUserNamePass();
      console.log(results);
      actions.setLoggedIn(true);
      // actions.setUserInfo(results);
      actions.setUserEmail('');
      actions.setUserPassword('');
    }),
    signupGoogle: thunk(async (actions, payload) => {
      const results = await signupUserGoogle();
      console.log(results);
      // actions.setLoggedIn(true);
      // actions.setUserInfo(results);
      actions.setUserEmail('');
      actions.setUserPassword('');
    }),
    loginGoogle: thunk(async (actions, payload) => {
      console.log('testing google login');
      const results = await loginUserGoogle();
      console.log(results);
      // actions.setUserInfo(results);
      // actions.setLoggedIn(true);
      actions.setUserEmail('');
      actions.setUserPassword('');
    }),
    loginUsername: thunk(async (actions, payload) => {
      console.log('testing login username and password');
      const results = await loginUserNamePass();
      console.log(results);
      // actions.setUserInfo(results);
      // actions.setLoggedIn(true);
      actions.setUserEmail('');
      actions.setUserPassword('');
    }),
    logoutUser: thunk(async (actions, payload) => {
      const results = await authLogout();
      actions.setLoggedIn(false);
      actions.setUserEmail('');
      actions.setUserPassword('');
      console.log(results);
    }),
  },
};

export const store = createStore(model);

// login actions

const loginUserNamePass = async () => {
  const { email, password } = store.getState().User;
  try {
    const loginResponse = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    console.log(loginResponse.user);
    console.log(loginResponse);
    console.log(loginResponse.user.currentUser());
    return loginResponse;
  } catch (err) {
    console.log(err);
  }
};

const signupUserNamePass = async () => {
  const { email, password } = store.getState().User;
  console.log(email, password);
  try {
    const signupResponse = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    console.log(signupResponse);
    await firebase.auth().currentUser.sendEmailVerification();
  } catch (err) {
    console.log(err);
  }
};
const signupUserGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    console.log(result);
    const token = result.credential.accessToken;
    // The signed-in user info.
    const { user } = result;
    console.log(user);
    await firebase.auth().currentUser.sendEmailVerification();
    return user;
  } catch (err) {
    console.log(err);
  }
};

const loginUserGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    console.log(result);
    const token = result.credential.accessToken;
    // The signed-in user info.
    const { user } = result;
    console.log(user);
    await firebase.auth().currentUser.sendEmailVerification();
    return user;
  } catch (err) {
    console.log(err);
  }
};

const authLogout = async () => {
  const result = await firebase.auth().signOut();
  return result;
};

export function StateProvider({ children }) {
  return <StoreProvider store={store}>{children}</StoreProvider>;
}
