import React, { memo } from 'react';
import styled from 'styled-components';
import hero from '../assets/fire4.jpg';

const StyledContainer = styled.div`
  max-width: 90%;
  padding: 2rem;
`;

const StyledHero = styled.div`
  min-width: 100vw;
  min-height: 35rem;
  height: 40vh;
  background-color: #70abaf;
  background-image: url(${() => hero});
  background-position: center;
  background-size: cover;
  @media (max-width: 800px) {
    min-height: 20rem;
  }
`;
const StyledHeroLeftDiv = styled.div`
  height: 100%;
  width: 60%;
  min-width 30rem;
  background: #1e2d2f;
  border-right: solid 5px #C57B57;
`;

const StyledHeroHeaderDiv = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  top: 15%;
  height: 10rem;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & > h1 {
    opacity: 1;
    display: block;
    color: white;
  }
`;

const Home = () => (
  <>
    <StyledHero>
      <StyledHeroLeftDiv>
        <StyledHeroHeaderDiv>
          <h1>
            Does Managing your speakers <br />
            feel like your juggling fire?
          </h1>
        </StyledHeroHeaderDiv>
      </StyledHeroLeftDiv>
    </StyledHero>
    <StyledContainer>
      <h1>It's time to speak up!</h1>
      <h3>For Speakers:</h3>
      <p>
        Ever wanted to get in to speaking or let others know you're open to
        speaking? Signup and let event organizers find you!
      </p>
      <h3>For Organizers:</h3>
      <p>
        Whether you're starting a new meetup or event, or looking to organize
        your old one, speak-up! can help you organize your speakers, and also
        find new ones!
      </p>
    </StyledContainer>
  </>
);

export default memo(Home);
