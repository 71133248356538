import React, { useEffect, lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'semantic-ui-css/semantic.min.css';
import { useActions } from 'easy-peasy';
import Header from './Components/Header';
import Home from './Pages/Home';
import squareSVG from './assets/squares.svg';

const CreateEventPromise = import('./Pages/CreateEvent');
const CreateEvent = lazy(() => CreateEventPromise);
const OrganizerPromise = import('./Pages/Organizer');
const Organizer = lazy(() => OrganizerPromise);

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f0f7f4;
    background-image: url("${() => squareSVG}");
  }
`;

const {
  REACT_APP_FIREBASE_apiKey,
  REACT_APP_FIREBASE_authDomain,
  REACT_APP_FIREBASE_databaseURL,
  REACT_APP_FIREBASE_projectId,
  REACT_APP_FIREBASE_storageBucket,
  REACT_APP_FIREBASE_messagingSenderId,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_apiKey,
  authDomain: REACT_APP_FIREBASE_authDomain,
  databaseURL: REACT_APP_FIREBASE_databaseURL,
  projectId: REACT_APP_FIREBASE_projectId,
  storageBucket: REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: REACT_APP_FIREBASE_messagingSenderId,
};
firebase.initializeApp(firebaseConfig);
console.log(process.env.NODE_ENV);
function App() {
  const setJWT = useActions(actions => actions.User.setJWT);
  const setLoggedIn = useActions(actions => actions.User.setLoggedIn);
  const setUserInfo = useActions(actions => actions.User.setUserInfo);
  const setUserStatePending = useActions(
    actions => actions.User.setUserStatePending
  );
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      console.log(user);
      if (user) {
        setLoggedIn(true);
        setUserInfo(user);
        (async () => {
          const token = await firebase
            .auth()
            .currentUser.getIdToken()
            .catch(e => console.log(e));
          setJWT(token);

          console.log(token);
        })();
      }
      setUserStatePending(false);
    });
  });
  return (
    <>
      <Header />
      <GlobalStyle whiteColor />
      <Suspense fallback={<h2>Loading…</h2>}>
        <Router>
          <Home path="/" default />
          <Organizer path="/organizers" />
          <CreateEvent path="/create-event" />
        </Router>
      </Suspense>
    </>
  );
}

export default App;
