import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import {
  Menu,
  Button,
  Modal,
  Form,
  Icon,
  Divider,
  Grid,
  Segment,
} from 'semantic-ui-react';
import { useStore, useActions } from 'easy-peasy';
import { navigate, Location } from '@reach/router';

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledMenu = styled(Menu)`
  border: none !important;
  padding: 1rem;
`;

const StyledModalContent = styled(Modal.Content)`
  padding: 4rem !important;
  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const StyledMenuItemBorder = styled(Menu.Item)`
  transition: all 0.5s !important;
  border: 1px solid !important;
  border-radius: 5px !important;
  &:hover {
    text-decoration: underline !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  transition: all 0.5s !important;
  &:hover {
    text-decoration: underline !important;
  }
`;

const StyledResponsiveDivider = styled(Divider)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const Header = props => {
  const [activeItem, setActiveItem] = useState('home');
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const handleMenueClick = (e, { name }) => {
    setActiveItem(name);
    navigate(`/${name}`);
  };
  const setUserEmail = useActions(actions => actions.User.setUserEmail);
  const setUserPassword = useActions(actions => actions.User.setUserPassword);
  const signupUsername = useActions(actions => actions.User.signupUsername);
  const loginUsername = useActions(actions => actions.User.loginUsername);
  const loginGoogle = useActions(actions => actions.User.loginGoogle);
  const signupGoogle = useActions(actions => actions.User.signupGoogle);
  const logoutUser = useActions(actions => actions.User.logoutUser);
  const { email, password, loggedIn, userStatePendinng } = useStore(
    state => state.User
  );

  useEffect(() => {
    if (loggedIn) {
      setSignupModalOpen(false);
      setLoginModalOpen(false);
    }
  }, [loggedIn]);

  useLayoutEffect(() => {
    const fullPath = window.location.pathname.trim();
    const firstPart = fullPath.split('/')[1];
    if (fullPath === '/') {
      return setActiveItem('home');
    }
    return setActiveItem(firstPart);
  }, [activeItem]);

  const toggleSignupModal = () => {
    console.log(signupModalOpen);
    setSignupModalOpen(!signupModalOpen);
  };
  const toggleLoginModal = () => {
    console.log(signupModalOpen);
    setLoginModalOpen(!loginModalOpen);
  };

  const onInputChange = e => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    }
    if (e.target.name === 'password') {
      setUserPassword(e.target.value);
    }
  };

  return (
    <StyledContainer>
      <StyledMenu pointing secondary>
        <Menu.Item
          name="home"
          active={activeItem === 'home'}
          onClick={handleMenueClick}
        />
        <Menu.Item
          name="speakers"
          active={activeItem === 'speakers'}
          onClick={handleMenueClick}
        />
        <Menu.Item
          name="organizers"
          active={activeItem === 'organizers'}
          onClick={handleMenueClick}
        />
        {!userStatePendinng && (
          <Menu.Menu position="right">
            {!loggedIn && (
              <>
                <StyledMenuItemBorder
                  name="signup"
                  // active={activeItem === 'logout'}
                  onClick={toggleSignupModal}
                />
                <StyledMenuItem
                  name="login"
                  // active={activeItem === 'logout'}
                  onClick={toggleLoginModal}
                />
              </>
            )}
            {loggedIn && (
              <StyledMenuItem
                name="logout"
                // active={activeItem === 'logout'}
                onClick={() => logoutUser()}
              />
            )}
          </Menu.Menu>
        )}
      </StyledMenu>
      <Modal open={signupModalOpen} onClose={toggleSignupModal} closeIcon>
        <StyledModalContent>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <Form onSubmit={() => signupUsername()}>
                <Form.Input
                  icon="mail"
                  iconPosition="left"
                  label="Email"
                  name="email"
                  value={email}
                  placeholder="super@awesome.com"
                  onChange={e => onInputChange(e)}
                  type="email"
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={e => onInputChange(e)}
                />

                <Button content="Signup" primary />
              </Form>
            </Grid.Column>

            <Grid.Column verticalAlign="middle" style={{ textAlign: 'center' }}>
              <Button color="google plus" onClick={() => signupGoogle()}>
                <Icon name="google" /> Signup with Google
              </Button>
            </Grid.Column>
          </Grid>
          <StyledResponsiveDivider vertical>Or</StyledResponsiveDivider>
          <Modal.Description />
        </StyledModalContent>
      </Modal>
      <Modal open={loginModalOpen} onClose={toggleLoginModal} closeIcon>
        <StyledModalContent>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <Form onSubmit={() => loginUsername()}>
                <Form.Input
                  icon="mail"
                  iconPosition="left"
                  label="Email"
                  name="email"
                  value={email}
                  placeholder="super@awesome.com"
                  onChange={e => onInputChange(e)}
                  type="email"
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={e => onInputChange(e)}
                />

                <Button content="Login" primary />
              </Form>
            </Grid.Column>

            <Grid.Column verticalAlign="middle" style={{ textAlign: 'center' }}>
              <Button color="google plus" onClick={() => loginGoogle()}>
                <Icon name="google" /> Login with Google
              </Button>
            </Grid.Column>
          </Grid>
          <StyledResponsiveDivider vertical>Or</StyledResponsiveDivider>
          <Modal.Description />
        </StyledModalContent>
      </Modal>
    </StyledContainer>
  );
};

export default Header;
